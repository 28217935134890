import React from 'react'
import styles from './emerald-guard.module.css'

export const EmeraldGuard = () => (
  <section className={styles.emeraldguard}>
    <h1>Welcome to The Emerald Order</h1>
    <section className={styles.content}>
      <section className={styles.left}>
        <WelcomeText />
      </section>
      <section className={styles.right}>
        <img
          className={styles.emerald}
          src="/emerald.png"
          alt="A beautiful emerald"
        />
        <p className={styles.quote}>
          <strong>
            Follow the Path. <br />
            Protect from the Shadows.
          </strong>
        </p>
      </section>
    </section>
    <footer>
      <p>
        -- Randalf Saa'den, Keeper of the Red Flame,
        <br /> Quartermaster of The Order, 5th Age
      </p>
    </footer>
    <section className={styles.content}>
      <Paths />
    </section>
  </section>
)

const WelcomeText = () => (
  <>
    <p>
      You have been selected, hand-picked, because you've shown promise in the
      arcane arts of javascript-mancy, demonstrated sound judgment and an
      affinity for defeating evil. Yet for all your prowess you are but a baby
      out of the cradle: ignorant, unknowing, with senses so dull and
      underdeveloped that you can't even begin to understand how much lies
      beyond your understanding and how far you are from achieving your true
      potential.
    </p>

    <p>
      <strong>We're the keepers of balance</strong>. We keep the world from
      burning. The discovery of JavaScript-mancy was a magnificent moment that
      started an Age of Wonders. But it also means that we now have a
      significant part of the population of Terra with the power to level
      mountains and split the world apart. We live in the shadows, we counsel,
      we nudge, we protect and as a last resort we strike with deadly might. But
      that means that we have to be better, wiser, smarter, stronger and faster
      than other practitioners of the arcane. And that's why we cultivate{' '}
      <strong>The Path</strong>. And The Path starts with{' '}
      <strong>The Book of Vim</strong>.
    </p>

    <p>
      This is <strong>The Book of Vim</strong>. A collection of teachings about{' '}
      <strong>Vim</strong> from our wisest masters of the arcane.{' '}
      <strong>Vim</strong> is our text editor because unlike ferals and wild
      mages, we carefully craft and improve our spells, an even adapt the REPL
      to our own personal inclinations and strengths. This book will become your
      closest companion for the next weeks, years and ages to come. Read it,
      learn it, practice it, until it becomes part of your very being. Then, and
      only then will you get a glimpse of the possibilities that lie beyond.
    </p>

    <p>
      Let's get started and lay the first stone of the foundation of the new
      you. An awesomer version, capable of anything you can imagine.
    </p>
  </>
)

const Paths = () => (
  <>
    <section className={styles.path}>
      <img src="/magic-book.png" className={styles.book} />
      <h2>Path of the Apprentice</h2>
      <p>
        Learn the principles and philosophy behind Vim. Lay the foundations you
        need to become an effective Vim user.{' '}
      </p>
      <p>In the path of the apprentice you'll discover:</p>
      <ul>
        <li>What is Vim and Why you should care</li>
        <li>The dragons of learning Vim</li>
        <li>How to setup Vim</li>
        <li>Tips and Tricks to learn Vim effectively</li>
        <li>How to create, open and save files in Vim</li>
        <li>How to exit Vim :)</li>
        <li>How to insert text in Vim</li>
        <li>How to move around blazingly fast using motions</li>
        <li>How to edit code like magic using operators</li>
        <li>The Vim Way for an Apprentice</li>
      </ul>
    </section>
    <section className={styles.path}>
      <img src="/hat.png" />
      <h2>Path of the Master</h2>
      <p>
        Deepen you knowledge in Vim. Practice and hone your Vim skills until you
        achieve mastery of the most useful core Vim features.
      </p>
      <p>In the path of the apprentice you'll discover:</p>
      <ul>
        <li>How Vim enhances copying and pasting</li>
        <li>What are Vim registers</li>
        <li>Undoing and redoing in Vim</li>
        <li>How to select text in Visual mode</li>
        <li>Being uber productive with command-line mode</li>
        <li>Advanced searching</li>
        <li>The magic substitute and global commands</li>
        <li>How to combine external tools with Vim</li>
        <li>More advanced insert mode features</li>
        <li>Creating, resizing and reshuffling windows</li>
        <li>Using tabs for altrnative workspaces</li>
        <li>Browsing through files</li>
        <li>Moving blazingly fast around files</li>
        <li>Reusable edit commands with Vim macros</li>
        <li>Multifile editing</li>
        <li>The Vim Way for a Master</li>
      </ul>
    </section>
    <section className={styles.path}>
      <img src="/dragon-familiar.png" />
      <h2>Path of the GrandMaster</h2>
      <p>
        Configure and extend Vim. Enhance your Vim experience with modern IDE
        features like autocompletion, semantic code navigation, refactoring and
        more.
      </p>
      <p>In the path of the apprentice you'll discover:</p>
      <ul>
        <li>How to configure Vim</li>
        <li>How to can improve your mappings to be more effective</li>
        <li>How to extend Vim with plugins</li>
        <li>Which are the most essential plugins</li>
        <li>Advanced autocompletion in Vim</li>
        <li>Semantic navigation in Vim</li>
        <li>Code Diagnostics in Vim</li>
        <li>Advanced Code Formatting in Vim</li>
        <li>Searching across code files in Vim</li>
        <li>Spellchecking in Vim</li>
        <li>Diffing in Vim</li>
        <li>
          How to setup a wondrous development environment with Vim and Tmux
        </li>
        <li>
          How to bring the full power of Vim to the terminal with terminal mode.
        </li>
        <li>The Vim Way for a Grandmaster</li>
      </ul>
    </section>
  </>
)
