import React from 'react'
import styles from './quote.module.css'

export const Quote = ({ text, author, avatar }) => (
  <aside className={styles.container}>
    <blockquote className={styles.quote}>
      <p>
        {text}
        <cite>- {author}</cite>
      </p>
      <footer>
        <img src={avatar} />
      </footer>
    </blockquote>
  </aside>
)
