import React, { useState } from 'react'
import styles from './home.module.css'
import { randomFromSelection } from '../../utils/random/random'
import { ClientOnly } from '../../components/client-only/client-only'
//import { useInterval } from '../../useInterval'

export const Home = () => {
  const [wizard, setWizard] = useState(getRandomWizard())

  // TODO: when I have more wizards
  // also make it possible to animate transitions
  // when switching between wizards
  //useInterval(() => {
  //setWizard(getRandomWizard(wizard))
  //}, 5000)

  return (
    <section className={styles.home}>
      <section className={styles.content}>
        <section className={styles.left}>
          <img
            className={styles.titleImage}
            src="/wizards-use-vim-title.png"
            alt="Wizards Use Vim"
            title="Wizards Use Vim"
          />
          <p>
            Unleash the mighty power of Vim. <br /> Become an awesome developer.
          </p>
        </section>
        <ClientOnly>
          <section
            className={`${styles.background} ${styles[wizard]}`}
          ></section>
        </ClientOnly>
      </section>
    </section>
  )
}

function getRandomWizard(lastWizard = '') {
  const wizards = ['wizard', 'sorceress'].filter(w => w !== lastWizard)
  return randomFromSelection(wizards)
}
