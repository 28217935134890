import React from 'react'

import Layout from '../components/layout'
import { Home } from '../components/home/home'
import { Pitch } from '../components/pitch/pitch'
import SEO from '../components/seo'
import { Arcana } from '../components/arcana/arcana'
import { AdventureMap } from '../components/adventure-map/adventure-map'
import { EmeraldGuard } from '../components/emerald-guard/emerald-guard'
import { Book } from '../components/book/book'
import { Author } from '../components/author/author'
import { Quote } from '../components/quote/quote'

const IndexPage = () => (
  <Layout>
    <SEO title="Wizards Use Vim" keywords={[`vim`, `neovim`, `programming`]} />
    <Home />
    <Arcana />
    <Pitch />
    <Quote
      author="Jaime González García. Author"
      text="Vim is not easy. In this book, I've strived to remove all the obstacles I faced through years of using Vim to make it approachable to everyone"
      avatar="/avatar-jaime-002.jpg"
    />
    <Book />
    <EmeraldGuard />
    <Author />
  </Layout>
)

export default IndexPage
